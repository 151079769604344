/* tslint:disable */
/* eslint-disable */
/**
 * Nest JS
 * Nest JS API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AnnouncementReqDto
 */
export interface AnnouncementReqDto {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementReqDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface AnnouncementResDto
 */
export interface AnnouncementResDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementResDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface AuthTokenEmailReqDto
 */
export interface AuthTokenEmailReqDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenEmailReqDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenEmailReqDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthTokenResDto
 */
export interface AuthTokenResDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenResDto
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface AuthTokenUsernameReqDto
 */
export interface AuthTokenUsernameReqDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenUsernameReqDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenUsernameReqDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface BookmarkReqDto
 */
export interface BookmarkReqDto {
    /**
     * 
     * @type {string}
     * @memberof BookmarkReqDto
     */
    'firstTitle': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkReqDto
     */
    'secondTitle': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkReqDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkReqDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface BookmarkResDto
 */
export interface BookmarkResDto {
    /**
     * 
     * @type {number}
     * @memberof BookmarkResDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BookmarkResDto
     */
    'firstTitle': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkResDto
     */
    'secondTitle': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkResDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkResDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'type': ContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'data': string;
}

export const ContentTypeEnum = {
    Text: 'text',
    File: 'file'
} as const;

export type ContentTypeEnum = typeof ContentTypeEnum[keyof typeof ContentTypeEnum];

/**
 * 
 * @export
 * @interface ConversationNameReqDto
 */
export interface ConversationNameReqDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationNameReqDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ConversationReqDto
 */
export interface ConversationReqDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationReqDto
     */
    'name': string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ConversationReqDto
     */
    'messages': Array<Message>;
}
/**
 * 
 * @export
 * @interface ConversationResDto
 */
export interface ConversationResDto {
    /**
     * 
     * @type {number}
     * @memberof ConversationResDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ConversationResDto
     */
    'name': string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ConversationResDto
     */
    'messages': Array<Message>;
    /**
     * 
     * @type {Array<UserResDto>}
     * @memberof ConversationResDto
     */
    'users': Array<UserResDto>;
}
/**
 * 
 * @export
 * @interface FilesReqDto
 */
export interface FilesReqDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilesReqDto
     */
    'filenames': Array<string>;
}
/**
 * 
 * @export
 * @interface FilesResDto
 */
export interface FilesResDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilesResDto
     */
    'urls': Array<string>;
}
/**
 * 
 * @export
 * @interface MarkdownReqDto
 */
export interface MarkdownReqDto {
    /**
     * 
     * @type {string}
     * @memberof MarkdownReqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MarkdownReqDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface MarkdownResDto
 */
export interface MarkdownResDto {
    /**
     * 
     * @type {number}
     * @memberof MarkdownResDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MarkdownResDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MarkdownResDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'role': MessageRoleEnum;
    /**
     * 
     * @type {Array<Content>}
     * @memberof Message
     */
    'contents': Array<Content>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'display'?: string;
}

export const MessageRoleEnum = {
    User: 'user',
    Assistant: 'assistant',
    System: 'system'
} as const;

export type MessageRoleEnum = typeof MessageRoleEnum[keyof typeof MessageRoleEnum];

/**
 * 
 * @export
 * @interface UserAvatarReqDto
 */
export interface UserAvatarReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserAvatarReqDto
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface UserEmailReqDto
 */
export interface UserEmailReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserEmailReqDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserEmailVerificationReqDto
 */
export interface UserEmailVerificationReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserEmailVerificationReqDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserEmailVerificationReqDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserPasswordReqDto
 */
export interface UserPasswordReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserPasswordReqDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserPrivilegesReqDto
 */
export interface UserPrivilegesReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserPrivilegesReqDto
     */
    'username': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPrivilegesReqDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrivilegesReqDto
     */
    'roles': Array<UserPrivilegesReqDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof UserPrivilegesReqDto
     */
    'credit': number;
}

export const UserPrivilegesReqDtoRolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;

export type UserPrivilegesReqDtoRolesEnum = typeof UserPrivilegesReqDtoRolesEnum[keyof typeof UserPrivilegesReqDtoRolesEnum];

/**
 * 
 * @export
 * @interface UserReqDto
 */
export interface UserReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserReqDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserReqDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserReqDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserResDto
 */
export interface UserResDto {
    /**
     * 
     * @type {number}
     * @memberof UserResDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserResDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResDto
     */
    'roles': Array<UserResDtoRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserResDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResDto
     */
    'credit': number;
}

export const UserResDtoRolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;

export type UserResDtoRolesEnum = typeof UserResDtoRolesEnum[keyof typeof UserResDtoRolesEnum];

/**
 * 
 * @export
 * @interface UserUsernameReqDto
 */
export interface UserUsernameReqDto {
    /**
     * 
     * @type {string}
     * @memberof UserUsernameReqDto
     */
    'username': string;
}

/**
 * AnnouncementApi - axios parameter creator
 * @export
 */
export const AnnouncementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFind: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnouncementReqDto} announcementReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerUpdate: async (announcementReqDto: AnnouncementReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementReqDto' is not null or undefined
            assertParamExists('announcementControllerUpdate', 'announcementReqDto', announcementReqDto)
            const localVarPath = `/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementApi - functional programming interface
 * @export
 */
export const AnnouncementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerFind(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerFind(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnouncementApi.announcementControllerFind']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AnnouncementReqDto} announcementReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementControllerUpdate(announcementReqDto: AnnouncementReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementControllerUpdate(announcementReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnouncementApi.announcementControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnouncementApi - factory interface
 * @export
 */
export const AnnouncementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerFind(options?: RawAxiosRequestConfig): AxiosPromise<AnnouncementResDto> {
            return localVarFp.announcementControllerFind(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnouncementReqDto} announcementReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementControllerUpdate(announcementReqDto: AnnouncementReqDto, options?: RawAxiosRequestConfig): AxiosPromise<AnnouncementResDto> {
            return localVarFp.announcementControllerUpdate(announcementReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementApi - object-oriented interface
 * @export
 * @class AnnouncementApi
 * @extends {BaseAPI}
 */
export class AnnouncementApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerFind(options?: RawAxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerFind(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnouncementReqDto} announcementReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public announcementControllerUpdate(announcementReqDto: AnnouncementReqDto, options?: RawAxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).announcementControllerUpdate(announcementReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetRoot: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetRoot(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetRoot(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppApi.appControllerGetRoot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetRoot(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appControllerGetRoot(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public appControllerGetRoot(options?: RawAxiosRequestConfig) {
        return AppApiFp(this.configuration).appControllerGetRoot(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthTokenEmailReqDto} authTokenEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateTokenByEmail: async (authTokenEmailReqDto: AuthTokenEmailReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authTokenEmailReqDto' is not null or undefined
            assertParamExists('authControllerCreateTokenByEmail', 'authTokenEmailReqDto', authTokenEmailReqDto)
            const localVarPath = `/auth/token/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authTokenEmailReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthTokenUsernameReqDto} authTokenUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateTokenByUsername: async (authTokenUsernameReqDto: AuthTokenUsernameReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authTokenUsernameReqDto' is not null or undefined
            assertParamExists('authControllerCreateTokenByUsername', 'authTokenUsernameReqDto', authTokenUsernameReqDto)
            const localVarPath = `/auth/token/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authTokenUsernameReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuthTokenEmailReqDto} authTokenEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreateTokenByEmail(authTokenEmailReqDto: AuthTokenEmailReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreateTokenByEmail(authTokenEmailReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerCreateTokenByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthTokenUsernameReqDto} authTokenUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreateTokenByUsername(authTokenUsernameReqDto: AuthTokenUsernameReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreateTokenByUsername(authTokenUsernameReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerCreateTokenByUsername']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthTokenEmailReqDto} authTokenEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateTokenByEmail(authTokenEmailReqDto: AuthTokenEmailReqDto, options?: RawAxiosRequestConfig): AxiosPromise<AuthTokenResDto> {
            return localVarFp.authControllerCreateTokenByEmail(authTokenEmailReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthTokenUsernameReqDto} authTokenUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateTokenByUsername(authTokenUsernameReqDto: AuthTokenUsernameReqDto, options?: RawAxiosRequestConfig): AxiosPromise<AuthTokenResDto> {
            return localVarFp.authControllerCreateTokenByUsername(authTokenUsernameReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthTokenEmailReqDto} authTokenEmailReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCreateTokenByEmail(authTokenEmailReqDto: AuthTokenEmailReqDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCreateTokenByEmail(authTokenEmailReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthTokenUsernameReqDto} authTokenUsernameReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCreateTokenByUsername(authTokenUsernameReqDto: AuthTokenUsernameReqDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCreateTokenByUsername(authTokenUsernameReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BookmarksApi - axios parameter creator
 * @export
 */
export const BookmarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerCreate: async (bookmarkReqDto: BookmarkReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookmarkReqDto' is not null or undefined
            assertParamExists('bookmarksControllerCreate', 'bookmarkReqDto', bookmarkReqDto)
            const localVarPath = `/bookmarks/bookmark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookmarksControllerDelete', 'id', id)
            const localVarPath = `/bookmarks/bookmark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerFindAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bookmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerUpdate: async (id: number, bookmarkReqDto: BookmarkReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookmarksControllerUpdate', 'id', id)
            // verify required parameter 'bookmarkReqDto' is not null or undefined
            assertParamExists('bookmarksControllerUpdate', 'bookmarkReqDto', bookmarkReqDto)
            const localVarPath = `/bookmarks/bookmark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookmarksApi - functional programming interface
 * @export
 */
export const BookmarksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookmarksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookmarksControllerCreate(bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookmarksControllerCreate(bookmarkReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookmarksApi.bookmarksControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookmarksControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookmarksControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookmarksApi.bookmarksControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookmarksControllerFindAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookmarkResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookmarksControllerFindAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookmarksApi.bookmarksControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookmarksControllerUpdate(id: number, bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookmarksControllerUpdate(id, bookmarkReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookmarksApi.bookmarksControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookmarksApi - factory interface
 * @export
 */
export const BookmarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookmarksApiFp(configuration)
    return {
        /**
         * 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerCreate(bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig): AxiosPromise<BookmarkResDto> {
            return localVarFp.bookmarksControllerCreate(bookmarkReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerDelete(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.bookmarksControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerFindAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<BookmarkResDto>> {
            return localVarFp.bookmarksControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BookmarkReqDto} bookmarkReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookmarksControllerUpdate(id: number, bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig): AxiosPromise<BookmarkResDto> {
            return localVarFp.bookmarksControllerUpdate(id, bookmarkReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookmarksApi - object-oriented interface
 * @export
 * @class BookmarksApi
 * @extends {BaseAPI}
 */
export class BookmarksApi extends BaseAPI {
    /**
     * 
     * @param {BookmarkReqDto} bookmarkReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public bookmarksControllerCreate(bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig) {
        return BookmarksApiFp(this.configuration).bookmarksControllerCreate(bookmarkReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public bookmarksControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return BookmarksApiFp(this.configuration).bookmarksControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public bookmarksControllerFindAll(options?: RawAxiosRequestConfig) {
        return BookmarksApiFp(this.configuration).bookmarksControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BookmarkReqDto} bookmarkReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public bookmarksControllerUpdate(id: number, bookmarkReqDto: BookmarkReqDto, options?: RawAxiosRequestConfig) {
        return BookmarksApiFp(this.configuration).bookmarksControllerUpdate(id, bookmarkReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerAddUserForUsers: async (id: number, userUsernameReqDto: UserUsernameReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conversationsControllerAddUserForUsers', 'id', id)
            // verify required parameter 'userUsernameReqDto' is not null or undefined
            assertParamExists('conversationsControllerAddUserForUsers', 'userUsernameReqDto', userUsernameReqDto)
            const localVarPath = `/conversations/conversation/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUsernameReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCloneForSpecificUser: async (id: number, userUsernameReqDto: UserUsernameReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conversationsControllerCloneForSpecificUser', 'id', id)
            // verify required parameter 'userUsernameReqDto' is not null or undefined
            assertParamExists('conversationsControllerCloneForSpecificUser', 'userUsernameReqDto', userUsernameReqDto)
            const localVarPath = `/conversations/conversation/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUsernameReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCreate: async (conversationReqDto: ConversationReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationReqDto' is not null or undefined
            assertParamExists('conversationsControllerCreate', 'conversationReqDto', conversationReqDto)
            const localVarPath = `/conversations/conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conversationsControllerDelete', 'id', id)
            const localVarPath = `/conversations/conversation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFind: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerUpdate: async (id: number, conversationReqDto: ConversationReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conversationsControllerUpdate', 'id', id)
            // verify required parameter 'conversationReqDto' is not null or undefined
            assertParamExists('conversationsControllerUpdate', 'conversationReqDto', conversationReqDto)
            const localVarPath = `/conversations/conversation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationNameReqDto} conversationNameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerUpdateName: async (id: number, conversationNameReqDto: ConversationNameReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conversationsControllerUpdateName', 'id', id)
            // verify required parameter 'conversationNameReqDto' is not null or undefined
            assertParamExists('conversationsControllerUpdateName', 'conversationNameReqDto', conversationNameReqDto)
            const localVarPath = `/conversations/conversation/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationNameReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerAddUserForUsers(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerAddUserForUsers(id, userUsernameReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerAddUserForUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerCloneForSpecificUser(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerCloneForSpecificUser(id, userUsernameReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerCloneForSpecificUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerCreate(conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerCreate(conversationReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerFind(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerFind(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerFind']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerUpdate(id: number, conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerUpdate(id, conversationReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationNameReqDto} conversationNameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerUpdateName(id: number, conversationNameReqDto: ConversationNameReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerUpdateName(id, conversationNameReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.conversationsControllerUpdateName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerAddUserForUsers(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerAddUserForUsers(id, userUsernameReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCloneForSpecificUser(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerCloneForSpecificUser(id, userUsernameReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCreate(conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerCreate(conversationReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerDelete(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFind(options?: RawAxiosRequestConfig): AxiosPromise<Array<ConversationResDto>> {
            return localVarFp.conversationsControllerFind(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationReqDto} conversationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerUpdate(id: number, conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerUpdate(id, conversationReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ConversationNameReqDto} conversationNameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerUpdateName(id: number, conversationNameReqDto: ConversationNameReqDto, options?: RawAxiosRequestConfig): AxiosPromise<ConversationResDto> {
            return localVarFp.conversationsControllerUpdateName(id, conversationNameReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {UserUsernameReqDto} userUsernameReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerAddUserForUsers(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerAddUserForUsers(id, userUsernameReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UserUsernameReqDto} userUsernameReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerCloneForSpecificUser(id: number, userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerCloneForSpecificUser(id, userUsernameReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConversationReqDto} conversationReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerCreate(conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerCreate(conversationReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerFind(options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerFind(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ConversationReqDto} conversationReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerUpdate(id: number, conversationReqDto: ConversationReqDto, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerUpdate(id, conversationReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ConversationNameReqDto} conversationNameReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsControllerUpdateName(id: number, conversationNameReqDto: ConversationNameReqDto, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationsControllerUpdateName(id, conversationNameReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilesReqDto} filesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteFiles: async (filesReqDto: FilesReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesReqDto' is not null or undefined
            assertParamExists('filesControllerDeleteFiles', 'filesReqDto', filesReqDto)
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerGetFiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadFiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FilesReqDto} filesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerDeleteFiles(filesReqDto: FilesReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerDeleteFiles(filesReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.filesControllerDeleteFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerGetFiles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerGetFiles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.filesControllerGetFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUploadFiles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUploadFiles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.filesControllerUploadFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {FilesReqDto} filesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteFiles(filesReqDto: FilesReqDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.filesControllerDeleteFiles(filesReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerGetFiles(options?: RawAxiosRequestConfig): AxiosPromise<FilesResDto> {
            return localVarFp.filesControllerGetFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadFiles(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.filesControllerUploadFiles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {FilesReqDto} filesReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerDeleteFiles(filesReqDto: FilesReqDto, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerDeleteFiles(filesReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerGetFiles(options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerGetFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesControllerUploadFiles(options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesControllerUploadFiles(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarkdownsApi - axios parameter creator
 * @export
 */
export const MarkdownsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerCreate: async (markdownReqDto: MarkdownReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'markdownReqDto' is not null or undefined
            assertParamExists('markdownsControllerCreate', 'markdownReqDto', markdownReqDto)
            const localVarPath = `/markdowns/markdown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markdownReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markdownsControllerDelete', 'id', id)
            const localVarPath = `/markdowns/markdown/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerFindAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/markdowns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerFindOne: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markdownsControllerFindOne', 'id', id)
            const localVarPath = `/markdowns/markdown/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerUpdate: async (id: number, markdownReqDto: MarkdownReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markdownsControllerUpdate', 'id', id)
            // verify required parameter 'markdownReqDto' is not null or undefined
            assertParamExists('markdownsControllerUpdate', 'markdownReqDto', markdownReqDto)
            const localVarPath = `/markdowns/markdown/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markdownReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarkdownsApi - functional programming interface
 * @export
 */
export const MarkdownsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarkdownsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markdownsControllerCreate(markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkdownResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markdownsControllerCreate(markdownReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarkdownsApi.markdownsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markdownsControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markdownsControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarkdownsApi.markdownsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markdownsControllerFindAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarkdownResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markdownsControllerFindAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarkdownsApi.markdownsControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markdownsControllerFindOne(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkdownResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markdownsControllerFindOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarkdownsApi.markdownsControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markdownsControllerUpdate(id: number, markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkdownResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markdownsControllerUpdate(id, markdownReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarkdownsApi.markdownsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarkdownsApi - factory interface
 * @export
 */
export const MarkdownsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarkdownsApiFp(configuration)
    return {
        /**
         * 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerCreate(markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig): AxiosPromise<MarkdownResDto> {
            return localVarFp.markdownsControllerCreate(markdownReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerDelete(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markdownsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerFindAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<MarkdownResDto>> {
            return localVarFp.markdownsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerFindOne(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MarkdownResDto> {
            return localVarFp.markdownsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarkdownReqDto} markdownReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markdownsControllerUpdate(id: number, markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig): AxiosPromise<MarkdownResDto> {
            return localVarFp.markdownsControllerUpdate(id, markdownReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarkdownsApi - object-oriented interface
 * @export
 * @class MarkdownsApi
 * @extends {BaseAPI}
 */
export class MarkdownsApi extends BaseAPI {
    /**
     * 
     * @param {MarkdownReqDto} markdownReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkdownsApi
     */
    public markdownsControllerCreate(markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig) {
        return MarkdownsApiFp(this.configuration).markdownsControllerCreate(markdownReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkdownsApi
     */
    public markdownsControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return MarkdownsApiFp(this.configuration).markdownsControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkdownsApi
     */
    public markdownsControllerFindAll(options?: RawAxiosRequestConfig) {
        return MarkdownsApiFp(this.configuration).markdownsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkdownsApi
     */
    public markdownsControllerFindOne(id: number, options?: RawAxiosRequestConfig) {
        return MarkdownsApiFp(this.configuration).markdownsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarkdownReqDto} markdownReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkdownsApi
     */
    public markdownsControllerUpdate(id: number, markdownReqDto: MarkdownReqDto, options?: RawAxiosRequestConfig) {
        return MarkdownsApiFp(this.configuration).markdownsControllerUpdate(id, markdownReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserReqDto} userReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (userReqDto: UserReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userReqDto' is not null or undefined
            assertParamExists('usersControllerCreate', 'userReqDto', userReqDto)
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDeleteById', 'id', id)
            const localVarPath = `/users/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendEmailVerification: async (userEmailVerificationReqDto: UserEmailVerificationReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEmailVerificationReqDto' is not null or undefined
            assertParamExists('usersControllerSendEmailVerification', 'userEmailVerificationReqDto', userEmailVerificationReqDto)
            const localVarPath = `/users/user/email-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEmailVerificationReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAvatarReqDto} userAvatarReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAvatar: async (userAvatarReqDto: UserAvatarReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAvatarReqDto' is not null or undefined
            assertParamExists('usersControllerUpdateAvatar', 'userAvatarReqDto', userAvatarReqDto)
            const localVarPath = `/users/user/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAvatarReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEmailReqDto} userEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmail: async (userEmailReqDto: UserEmailReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEmailReqDto' is not null or undefined
            assertParamExists('usersControllerUpdateEmail', 'userEmailReqDto', userEmailReqDto)
            const localVarPath = `/users/user/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEmailReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmailVerified: async (userEmailVerificationReqDto: UserEmailVerificationReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEmailVerificationReqDto' is not null or undefined
            assertParamExists('usersControllerUpdateEmailVerified', 'userEmailVerificationReqDto', userEmailVerificationReqDto)
            const localVarPath = `/users/user/email-verified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEmailVerificationReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserPasswordReqDto} userPasswordReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePassword: async (userPasswordReqDto: UserPasswordReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPasswordReqDto' is not null or undefined
            assertParamExists('usersControllerUpdatePassword', 'userPasswordReqDto', userPasswordReqDto)
            const localVarPath = `/users/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPasswordReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserPrivilegesReqDto} userPrivilegesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePrivileges: async (userPrivilegesReqDto: UserPrivilegesReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPrivilegesReqDto' is not null or undefined
            assertParamExists('usersControllerUpdatePrivileges', 'userPrivilegesReqDto', userPrivilegesReqDto)
            const localVarPath = `/users/user/privileges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPrivilegesReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUsername: async (userUsernameReqDto: UserUsernameReqDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUsernameReqDto' is not null or undefined
            assertParamExists('usersControllerUpdateUsername', 'userUsernameReqDto', userUsernameReqDto)
            const localVarPath = `/users/user/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUsernameReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserReqDto} userReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(userReqDto: UserReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(userReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDeleteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFind(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFind(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerFind']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindOne(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindOne(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSendEmailVerification(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSendEmailVerification(userEmailVerificationReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerSendEmailVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserAvatarReqDto} userAvatarReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateAvatar(userAvatarReqDto: UserAvatarReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateAvatar(userAvatarReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserEmailReqDto} userEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateEmail(userEmailReqDto: UserEmailReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateEmail(userEmailReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateEmailVerified(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateEmailVerified(userEmailVerificationReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateEmailVerified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserPasswordReqDto} userPasswordReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePassword(userPasswordReqDto: UserPasswordReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePassword(userPasswordReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserPrivilegesReqDto} userPrivilegesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePrivileges(userPrivilegesReqDto: UserPrivilegesReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePrivileges(userPrivilegesReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdatePrivileges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateUsername(userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateUsername(userUsernameReqDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateUsername']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UserReqDto} userReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(userReqDto: UserReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerCreate(userReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserResDto>> {
            return localVarFp.usersControllerFind(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne(options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerFindOne(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendEmailVerification(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersControllerSendEmailVerification(userEmailVerificationReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAvatarReqDto} userAvatarReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAvatar(userAvatarReqDto: UserAvatarReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdateAvatar(userAvatarReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEmailReqDto} userEmailReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmail(userEmailReqDto: UserEmailReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdateEmail(userEmailReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmailVerified(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdateEmailVerified(userEmailVerificationReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserPasswordReqDto} userPasswordReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePassword(userPasswordReqDto: UserPasswordReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdatePassword(userPasswordReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserPrivilegesReqDto} userPrivilegesReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePrivileges(userPrivilegesReqDto: UserPrivilegesReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdatePrivileges(userPrivilegesReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserUsernameReqDto} userUsernameReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUsername(userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig): AxiosPromise<UserResDto> {
            return localVarFp.usersControllerUpdateUsername(userUsernameReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UserReqDto} userReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCreate(userReqDto: UserReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerCreate(userReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDelete(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteById(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFind(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFind(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindOne(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerSendEmailVerification(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerSendEmailVerification(userEmailVerificationReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAvatarReqDto} userAvatarReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateAvatar(userAvatarReqDto: UserAvatarReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateAvatar(userAvatarReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEmailReqDto} userEmailReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateEmail(userEmailReqDto: UserEmailReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateEmail(userEmailReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEmailVerificationReqDto} userEmailVerificationReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateEmailVerified(userEmailVerificationReqDto: UserEmailVerificationReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateEmailVerified(userEmailVerificationReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserPasswordReqDto} userPasswordReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePassword(userPasswordReqDto: UserPasswordReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePassword(userPasswordReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserPrivilegesReqDto} userPrivilegesReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePrivileges(userPrivilegesReqDto: UserPrivilegesReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePrivileges(userPrivilegesReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserUsernameReqDto} userUsernameReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateUsername(userUsernameReqDto: UserUsernameReqDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateUsername(userUsernameReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}



